import React, { useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import { FaSearch, FaCheckCircle, FaHourglassStart, FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import debounce from "lodash/debounce";
import { TablePagination } from "@mui/material";
import UserClasses from '../users/Users.module.css'

function Orders() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [activeStatus, setActiveStatus] = useState(2); // State to track active button
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchOrders = (status = 1) => {
        setLoading(true);
        const body = JSON.stringify({ OrderStatus: status });
        Services.listOrders("POST", body, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setData(response.Orders);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    const searchForOrders = useCallback(
        debounce((searchString, status = 1) => {
            setLoading(true);
            const body = JSON.stringify({
                SearchString: searchString,
                OrderStatus: status,
            });
            Services.listOrders("POST", body, token)
                .then((response) => {
                    setLoading(false);
                    if (response.Status === 1) {
                        const filteredOrders = response.Orders.filter(
                            order =>
                                order.UserName.toLowerCase().includes(searchString.toLowerCase())
                        );
                        setData(filteredOrders);
                    } else if (response.Status === 0) {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("Something went wrong. Please try again.");
                    console.error(err);
                });
        }, 300),
        []
    );

    useEffect(() => {
        fetchOrders(activeStatus);
    }, [activeStatus]);

    useEffect(() => {
        if (searchString) {
            searchForOrders(searchString, activeStatus);
        } else {
            fetchOrders(activeStatus);
        }
    }, [searchString, searchForOrders, activeStatus]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const currentData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Layout Active={"Orders"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Orders</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={CommonClasses["control-btns"]}>
                    <div className={UserClasses["search-container"]} style={{ width: '220px' }}>
                        <FaSearch className={UserClasses["search-icon"]} />
                        <input
                            type="text"
                            placeholder="Search Orders By User Name"
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            className={UserClasses["search-btn"]}
                        />
                    </div>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    <div className={CommonClasses["table-control-btns"]}>
                        <div
                            className={`${CommonClasses["button"]} ${activeStatus === 2 ? CommonClasses["active"] : ""}`}
                            onClick={() => setActiveStatus(2)}
                        >
                            <FaHourglassStart /> Pending Orders
                        </div>
                        <div
                            className={`${CommonClasses["button"]} ${activeStatus === 1 ? CommonClasses["active"] : ""}`}
                            onClick={() => setActiveStatus(1)}
                        >
                            <FaCheckCircle /> Completed Orders
                        </div>
                        <div
                            className={`${CommonClasses["button"]} ${activeStatus === 3 ? CommonClasses["active"] : ""}`}
                            onClick={() => setActiveStatus(3)}
                        >
                            <FaTimesCircle /> Canceled Orders
                        </div>
                        <div
                            className={`${CommonClasses["button"]} ${activeStatus === 4 ? CommonClasses["active"] : ""}`}
                            onClick={() => setActiveStatus(4)}
                        >
                            <FaTimesCircle /> In Transit Orders
                        </div>
                    </div>
                    {loading ? (
                        <div className={CommonClasses["loading"]}>Loading...</div>
                    ) : (
                        <div className={`${CommonClasses["Table"]} ${CommonClasses["fade-in"]}`}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>OrderID</th>
                                        <th>UserName</th>
                                        <th>Event Id</th>
                                        <th>EventName</th>
                                        <th>CreatedAt</th>
                                        <th>UpdatedAt</th>
                                        <th>OrderStatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.length > 0 ? (
                                        currentData.map((item) => (
                                            <tr
                                                key={item.OrderID}
                                                id={item.OrderID}
                                                name={item.EventName}
                                                onClick={() => navigate(`/order-details/${item.OrderID}`)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <td>{item.OrderID}</td>
                                                <td>{item.UserName}</td>
                                                <td>{item.EventID}</td>
                                                <td>{item.EventName || 'NA'}</td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.CreatedAt}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.UpdatedAt}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    {item.OrderStatus === 1 ? "Completed" : item.OrderStatus === 2 ? "Pending" : item.OrderStatus === 3 ? "Cancelled" : 'NA'}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" style={{ textAlign: 'center' }}>No Orders Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <TablePagination
                                component="div"
                                count={data.length}
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                rowsPerPageOptions={[5, 10, 25]}
                                className={CommonClasses["pagination"]}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Orders;
